import React, { useEffect } from "react";

function Home() {
  //Executes once during initialization
  useEffect(() => {
    document.title = process.env.REACT_APP_MAIN_TITLE;
    deactivateSideBarMenu();
  }, []);

  return (
    <main className="col-sm-12 col-md-12 col-lg-9 col-xl-10">
      <h1>Document Center</h1>
      <p>
        Document Center allows you to search city legislation, public meeting
        records and agreements from one central location. Over time, additional
        document types will be added.{" "}
      </p>
      <p>
        Requests for public records not located on this site can be submitted
        through our{" "}
        <a
          href="https://bellevuewa.govqa.us/WEBAPP/_rs/(S(xkanclqt0ptzvf2zwugaifv5))/SupportHome.aspx"
          aria-label="Link to the Public Records portal"
        >
          Public Records portal
        </a>
        . Requests for police public records and building and land use permit
        records can also be submitted online through this portal. Please note
        that you will be required to create an account for the Public Records
        Portal and provide at least one contact method.
      </p>
    </main>
  );
}

function deactivateSideBarMenu() {
  let list = document.getElementById("sidebar-menu").getElementsByTagName("a");
  for (let i = 0; i < list.length; i++) {
    let elem = list[i];
    elem.classList.remove("active");
    elem
      .getElementsByTagName("i")[0]
      .classList.remove("fa", "fa-chevron-right");
  }
}

export default Home;
