import React, { useState } from "react";
import { Link, withRouter } from "react-router-dom";
import { Link as ScrollToLink } from "react-scroll";

function Header(props) {
  const [queryParams, setQueryParams] = useState("");

  const handleSubmit = (event) => {
    document.getElementById("azure-search-form-input").value = "";

    if (queryParams !== "") {
      // Prevent default native behavior of submit
      event.preventDefault();

      // Push the new location to React Router DOM
      props.history.push("/Search?text=" + queryParams);
    }
  };

  return (
    <header>
      <ScrollToLink
        href="#main-content"
        to="main-content"
        className="visually-hidden focusable"
        tabIndex="1"
        onClick={(e) => e.target.blur()}
      >
        Skip to main content
      </ScrollToLink>
      <nav
        className="navbar navbar-expand-lg navbar-dark navbar-custom justify-content-between"
        role="navigation"
        aria-label="Header navigation"
      >
        <div className="navbar-header">
          <a
            className="navbar-brand"
            href="https://bellevuewa.gov"
            title="Go to Bellevue Wa"
          >
            City of Bellevue, WA
          </a>
          <span class="h4">|</span>
          <Link className="navbar-brand ml-2" to="/">
            <span className="department">Document Center</span>
          </Link>
        </div>
        <div className="navbar-right pl-4">
          <div className="form-group mt-1 mb-1 mr-3" id="azure-search-form">
            <label htmlFor="azure-search-form-input" className="sr-only">
              Search{" "}
            </label>
            <div className="input-group">
              <input
                id="azure-search-form-input"
                name="dcSearch"
                onChange={(event) =>
                  setQueryParams(encodeURI(event.target.value))
                }
                onKeyDown={(event) => {
                  //13 = "Enter" key
                  if (event.keyCode === 13) {
                    event.target.blur();
                    handleSubmit(event);
                  }
                }}
                type="search"
                className="form-control"
                autoComplete="off"
                aria-autocomplete="list"
                aria-haspopup="true"
                aria-label="Input box for search text"
                placeholder="Search"
              />
              <span className="input-group-btn">
                <button
                  className="btn btn-default btn-tertiary"
                  type="button"
                  onClick={handleSubmit}
                >
                  Go!
                </button>
              </span>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default withRouter(Header);
