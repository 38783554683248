import React from "react";
import logo from "../img/logo-graphic.png";
import background from "../img/footer-bg.svg";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <footer
      className="footer container-fluid"
      role="contentinfo"
      style={{ backgroundImage: `url(${background})` }}
    >
      <div className="footer-content">
        <div className="row">
          <aside className="col-sm-4">
            <h2>
              <img className="mr-2" alt="City of Bellevue seal" src={logo} />
              <span className="align-middle">City of Bellevue, WA</span>
            </h2>
            <ul className="list-unstyled">
              <li>450 110th Avenue NE</li>
              <li>Bellevue, WA 98004</li>
              <li>
                <a
                  href="https://www.google.com/maps/place/Bellevue+City+Hall/@47.6144305,-122.1929512,18z"
                  tabIndex="-1"
                >
                  <em className="fa fa-map-marker"></em> Directions to City Hall
                </a>
              </li>
              <li>
                <em className="far fa-clock"></em> 8am - 5pm Weekdays
              </li>
            </ul>
            <nav
              role="navigation"
              id="block-footermenucontacts"
              className="block block-menu navigation menu--footer-menu-contacts"
            >
              <ul className="list-unstyled">
                <li>
                  <a href="tel:4254526800" className="phone">
                    <i className="fa fas fa-phone" aria-hidden="true"></i>{" "}
                    <span className="link-text">425-452-6800</span>
                  </a>
                </li>
                <li>
                  <a
                    href="mailto:servicefirst@bellevuewa.gov"
                    className="email"
                  >
                    <i className="fa fas fa-envelope" aria-hidden="true"></i>{" "}
                    <span className="link-text">
                      servicefirst@bellevuewa.gov
                    </span>
                  </a>
                </li>

                <li>
                  <a
                    href="https://bellevuewa.gov/city-government/departments/civic-services/mybellevue/customer-assistance/"
                    className="cob-icon"
                  >
                    MyBellevue Customer Assistance
                  </a>
                </li>

                <li>
                  <a href="https://bellevuewa.gov/contact-us">
                    <i className="fa fas fa-envelope" aria-hidden="true"></i>{" "}
                    <span className="link-text">
                      Contact Us / Employee Directory
                    </span>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.governmentjobs.com/careers/bellevuewa"
                    className="briefcase"
                  >
                    <i className="fa fas fa-briefcase" aria-hidden="true"></i>{" "}
                    <span className="link-text">Careers</span>
                  </a>
                </li>
                <li>
                  <a href="https://bellevuewa.gov/city-government/departments/city-managers-office/accessibility">
                    <i className="fa fas fa-wheelchair" aria-hidden="true"></i>{" "}
                    <span className="link-text">ADA/Title VI Notices</span>
                  </a>
                </li>
              </ul>
            </nav>
          </aside>
          <aside className="col-sm-4">
            <h2>Languages</h2>
            <ul className="list-unstyled">
              <li>
                <a href="https://bellevuewa.gov/spanish-espanol/welcome">
                  Español
                </a>
              </li>
              <li>
                <a href="https://bellevuewa.gov/chinese/welcome">中文</a>
              </li>
              <li>
                <a href="https://bellevuewa.gov/vietnamese/welcome">
                  Tiếng Việt
                </a>
              </li>
              <li>
                <a href="https://bellevuewa.gov/korean/welcome">한국어</a>
              </li>
              <li>
                <a href="https://bellevuewa.gov/russian/welcome">Pусский</a>
              </li>
            </ul>
            <h2>Language Lines</h2>
            <ul className="list-unstyled">
              <li>
                Español (Spanish):&nbsp;
                <a href="tel:1-844-590-8948">1-844-590-8948</a>
              </li>
              <li>
                廣東話 (Cantonese):&nbsp;
                <a href="tel:1-844-537-0364">1-844-537-0364</a>
              </li>
              <li>
                普通话 (Mandarin):&nbsp;
                <a href="tel:1-844-590-8945">1-844-590-8945</a>
              </li>
              <li>
                한국어 (Korean):&nbsp;
                <a href="tel:1-844-537-0365">1-844-537-0365</a>
              </li>
              <li>
                ਪੰਜਾਬੀ (Punjabi):&nbsp;
                <a href="tel:1-844-590-8946">1-844-590-8946</a>
              </li>
              <li>
                Русский (Russian):&nbsp;
                <a href="tel:1-844-590-8947">1-844-590-8947</a>
              </li>
              <li>
                Tiếng Việt (Vietnamese):&nbsp;
                <a href="tel:1-844-590-8949">1-844-590-8949</a>
              </li>
            </ul>
          </aside>
          <aside className="col-sm-4">
            <h2>Site Navigation</h2>
            <ul className="list-unstyled">
              <li>
                <a href="https://bellevuewa.gov">Bellevue Home</a>
              </li>
              <li>
                <a href="https://bellevuewa.gov/city-government">
                  City Government
                </a>
              </li>
              <li>
                <a href="https://bellevuewa.gov/discover-bellevue">
                  Discover Bellevue
                </a>
              </li>
              <li>
                <a href="https://bellevuewa.gov/doing-business">
                  Doing Business
                </a>
              </li>
              <li>
                <a href="https://bellevuewa.gov/public-safety">Public Safety</a>
              </li>
              <li>
                <a href="https://bellevuewa.gov/resident-resources">
                  Resident Resources
                </a>
              </li>
            </ul>
          </aside>
        </div>
        <div className="row">
          <aside className="col-sm-12">
            <div>
              <p>
                © 2021&nbsp;City of Bellevue | All Rights Reserved. |{" "}
                <a href="https://bellevuewa.gov/city-government/departments/city-managers-office/accessibility">
                  ADA/Title VI Notices
                </a>{" "}
                | <a href="https://bellevuewa.gov/terms-of-use">Terms of Use</a>{" "}
                |{" "}
                <a href="https://bellevuewa.gov/privacy-security">
                  Privacy Policy
                </a>{" "}
                |{" "}
                <Link
                  to="/SiteMap"
                  onClick={() => {
                    window.scrollTo(0, 0); //scroll to the top of the page
                  }}
                >
                  Site Map
                </Link>
                &nbsp;
              </p>
            </div>
          </aside>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
