import React, { createRef, Fragment, useState, useEffect } from "react";
import { useLocation, withRouter } from "react-router-dom";
import queryString from "query-string";
import { decode } from "html-entities";
import Config from "../config.json";
import ReactPaginate from "react-paginate";
import ReactHtmlParser from "react-html-parser";
import { Helmet } from "react-helmet";
import { Chip } from "@react-md/chip";
import "../react-md-styles.css";

function Folder(props) {
  const folderUrl =
    process.env.REACT_APP_STORAGE_ACCOUNT_URL +
    "/" +
    Config.storage.containerName +
    "/";
  const config = getFolderConfig(Config.storage.folders, props.folder);
  const { search } = useLocation();
  const queryParams = queryString.parse(search);
  const [getBlobsFailed, setGetBlobsFailed] = useState(false);
  const [allDocuments, setAllDocuments] = useState([]);
  const [documents, setDocuments] = useState([]);
  const [filters, setFilters] = useState("");
  const [filterSelections, setFilterSelections] = useState(new Map());
  const [showFilters, setShowFilters] = useState(false);
  const [sortByContainer] = useState(createRef());
  const [isSortByOpen, setIsSortByOpen] = useState(false);
  const [sortTypeLabel, setSortTypeLabel] = useState(config.defaultSortLabel);
  const [sortType, setSortType] = useState(
    config.defaultSortKey + "_" + config.defaultSortDatatype
  );
  const [sortOrder, setSortOrder] = useState(
    config.defaultSortAsc ? "ASC" : "DESC"
  );
  const [pageNumber, setPageNumber] = useState(1);
  const documentsPerPage = Config.storage.recordsPerPage;
  const pagesVisited = (pageNumber - 1) * documentsPerPage;
  const pageCount = Math.ceil(documents.length / documentsPerPage);
  const changePage = ({ selected }) => {
    window.scrollTo(0, 0);
    let selectedPageNum = selected + 1;
    setPageNumber(selectedPageNum);
  };

  async function getBlobs() {
    try {
      const { BlobServiceClient } = require("@azure/storage-blob");
      const blobServiceClient = new BlobServiceClient(
        process.env.REACT_APP_STORAGE_ACCOUNT_URL +
          process.env.REACT_APP_STORAGE_SAS
      );
      const containerClient = blobServiceClient.getContainerClient(
        Config.storage.containerName
      );

      let blobs = containerClient.listBlobsFlat({
        prefix: config.name + "/",
        includeMetadata: true,
      });

      const blobsArr = [];
      for await (const blob of blobs) {
        blobsArr.push(blob);
      }

      let sortedArr = getSortedData(
        blobsArr,
        config.defaultSortKey,
        config.defaultSortAsc,
        config.defaultSortDatatype
      );

      setAllDocuments([...sortedArr]);
      initializeFilters(sortedArr);
    } catch (err) {
      console.log("Error retrieving records. Error message: " + err);
      setGetBlobsFailed(true);
    }
  }

  function initializeFilters(allDocsArr) {
    let filterableFields = config.metadata.filter((m) => m.filterable === true);
    let filtersArr = [];
    if (filterableFields.length > 0) {
      for (let i = 0; i < filterableFields.length; i++) {
        let values = allDocsArr.map(function (b) {
          return b.metadata[filterableFields[i].id];
        });

        let uniqueValues = new Set(values);

        filtersArr.push({
          id: filterableFields[i].id,
          name: filterableFields[i].name,
          options: Array.from(uniqueValues).sort(),
        });
      }

      setFilters([...filtersArr]);
    }

    if (queryParams.filters) {
      setShowFilters(true);
      let previous = allDocsArr;
      let queryParamFilters = queryParams.filters.split(";");
      let validFiltersCount = 0;
      let filtersMap = new Map();

      for (let i = 0; i < queryParamFilters.length; i++) {
        let id = queryParamFilters[i].split(",")[0];
        let value = queryParamFilters[i].split(",")[1];
        let validValues = filtersArr.find((f) => f.id === id)?.options;

        if (
          id !== undefined &&
          value !== undefined &&
          validValues !== undefined &&
          validValues.includes(value) &&
          filtersMap.get(id) === undefined
        ) {
          filtersMap.set(id, value);
          setFilterSelections(filterSelections.set(id, value));
          previous = previous.filter((d) => d.metadata[id] === value);
          validFiltersCount++;
        }
      }

      if (validFiltersCount > 0) {
        updateFiltersBtnText(validFiltersCount);
        setDocuments([...previous]);
      } else {
        setDocuments([...allDocsArr]);
      }
    } else {
      setDocuments([...allDocsArr]);
    }
  }

  //Executes once during initialization
  useEffect(() => {
    document.title = process.env.REACT_APP_MAIN_TITLE + " - " + config.title;
    updateSideBarMenu(config.name);
    document.addEventListener("mousedown", handleClickOutsideSortByContainer);

    getBlobs();

    // Equivalent to componentWillUnmount
    return () => {
      document.removeEventListener(
        "mousedown",
        handleClickOutsideSortByContainer
      );
    };
  }, []);

  useEffect(() => {
    let sortArr = sortType.split("_");
    let isAsc = sortOrder === "ASC";
    let arr = getSortedData(documents, sortArr[0], isAsc, sortArr[1]);
    setDocuments([...arr]);
    setPageNumber(1);
  }, [sortType, sortOrder]);

  useEffect(() => {
    if (showFilters === true) {
      document.getElementById("filtersToggleBtn")?.classList.add("active");
      document.getElementById("filters")?.classList.add("show");
    } else {
      document.getElementById("filtersToggleBtn")?.classList.remove("active");
      document.getElementById("filters")?.classList.remove("show");
    }
  }, [showFilters]);

  function handleSortByButtonClick() {
    setIsSortByOpen(!isSortByOpen);
  }

  function handleClickOutsideSortByContainer(event) {
    if (
      sortByContainer.current &&
      !sortByContainer.current.contains(event.target)
    ) {
      setIsSortByOpen(false);
    }
  }

  function onClickSortTypeSelected(e, label) {
    setSortType(e.target.getAttribute("value"));
    setSortTypeLabel(label);
    setIsSortByOpen(false);
  }

  function onClickSortOrderSelected(e) {
    setSortOrder(e.target.getAttribute("value"));
    setIsSortByOpen(false);
  }

  function renderSortByDropDown() {
    if (documents != null && documents.length > 0) {
      return (
        <div id="sort-by-container" ref={sortByContainer} className="ml-3 mb-3">
          <button
            id="sort-by-button"
            type="button"
            className="btn btn-outline-primary"
            onClick={handleSortByButtonClick}
          >
            <i
              class={
                sortOrder === "ASC"
                  ? "fa fas fa-sort-amount-up"
                  : "fa fas fa-sort-amount-down"
              }
            ></i>
            Sort by: {sortTypeLabel}
          </button>
          {isSortByOpen && (
            <div id="sort-by-flyout">
              <ul aria-labelledby="sort-by-button">
                <div
                  id="sort-order-container"
                  class="btn-group"
                  role="group"
                  aria-label="Sort order options"
                >
                  <button
                    type="button"
                    onClick={onClickSortOrderSelected}
                    className={sortOrder === "ASC" && "selected"}
                    value="ASC"
                  >
                    A-Z
                  </button>
                  <button
                    type="button"
                    onClick={onClickSortOrderSelected}
                    className={sortOrder === "DESC" && "selected"}
                    value="DESC"
                  >
                    Z-A
                  </button>
                </div>
                {config.sortOptions?.map((m, i) => (
                  <li
                    onClick={(e) => {
                      onClickSortTypeSelected(e, m.label);
                    }}
                    value={m.value + "_" + m.datatype}
                    className={sortType.split("_")[0] === m.value && "selected"}
                  >
                    <span>
                      {sortType.split("_")[0] === m.value && (
                        <i class="fa fas fa-check" aria-hidden="true"></i>
                      )}
                    </span>
                    {m.label}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      );
    }
  }

  function updateFiltersBtnText(count) {
    if (count > 0) {
      document.getElementById("filtersToggleBtn").innerHTML =
        '<i class="fa fas fa-filter"></i> Filters (' + count + ")";
    } else {
      document.getElementById("filtersToggleBtn").innerHTML =
        '<i class="fa fas fa-filter"></i> Filters';
    }
  }

  function handleFilterChipClick(id, value) {
    setPageNumber(1);

    let sortArr = sortType.split("_");
    let isAsc = sortOrder === "ASC";

    if (value === filterSelections.get(id)) {
      props.history.push(config.routePath);

      setFilterSelections(filterSelections.set(id, null));
      let sortedArr = getSortedData(
        allDocuments,
        sortArr[0],
        isAsc,
        sortArr[1]
      );
      setDocuments([...sortedArr]);
    } else {
      let filterQueryParam = "";
      filterSelections.forEach((value, key) => {
        if (key !== id) filterQueryParam += key + "," + value + ";";
      });
      filterQueryParam += id + "," + value;
      props.history.push(config.routePath + "?filters=" + filterQueryParam);

      setFilterSelections(filterSelections.set(id, value));
      let arr = allDocuments.filter((d) => d.metadata[id] === value);
      let sortedArr = getSortedData(arr, sortArr[0], isAsc, sortArr[1]);
      setDocuments([...sortedArr]);
    }

    let count = 0;
    filterSelections.forEach((value, key) => {
      if (value !== null) {
        count++;
      }
    });

    updateFiltersBtnText(count);
  }

  function renderFilterChips() {
    return (
      <div className="row mb-0 mt-0">
        <div className="col-12">
          <div id="filters" className="col-12 collapse ">
            <label for="filter-buttons-group" class="sr-only">
              Filter by metadata field(s)
            </label>
            <div
              name="filter-buttons-group"
              class="btn-group"
              role="group"
              aria-label="Filter buttons by metadata field(s)"
            >
              {filters.map((filter) => {
                return (
                  <Fragment>
                    <label class="font-weight-bold mr-2">{filter.name}</label>
                    <ul className="pl-0">
                      {filter.options.map((option) => {
                        return (
                          <li className="d-inline">
                            <Chip
                              theme="solid"
                              disableIconTransition
                              role="button"
                              key={option}
                              selected={
                                filterSelections.get(filter.id) === option
                              }
                              onClick={(e) => {
                                e.currentTarget.blur();
                                handleFilterChipClick(filter.id, option);
                              }}
                            >
                              {option}
                            </Chip>
                          </li>
                        );
                      })}
                    </ul>
                  </Fragment>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }

  function renderDocuments() {
    if (documents != null && documents.length > 0) {
      return documents
        .slice(pagesVisited, pagesVisited + documentsPerPage)
        .map((document, index) => {
          return (
            <div className=" col-xl-6 col-lg-12 col-md-12 col-sm-12 d-flex flex-fill">
              <div
                className="card mb-2 w-100"
                key={document.name.split("/")[1]}
              >
                <div className="card-body d-flex flex-column">
                  <h2 className="card-title h4">
                    {document.name.split("/")[1]}
                  </h2>
                  <dl className="card-text row mb-2">
                    {config.metadata?.map((m, i) =>
                      document.metadata[m.id] === undefined ? (
                        <Fragment>
                          <dt className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                            {m.name}
                          </dt>
                          <dd className="col-xl-8 col-lg-8 col-md-6 col-sm-12"></dd>
                        </Fragment>
                      ) : (
                        <Fragment key={m.name}>
                          <dt className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                            {m.name}
                          </dt>
                          <dd className="col-xl-8 col-lg-8 col-md-6 col-sm-12">
                            {Config.storage.metadataDateTimeFields.includes(
                              m.id
                            )
                              ? document.metadata[m.id].split(" ")[0]
                              : decode(document.metadata[m.id])}
                          </dd>
                        </Fragment>
                      )
                    )}
                    {config.properties?.map(
                      (p, i) =>
                        p.id === "lastModified" && (
                          <Fragment key={p.name}>
                            <dt className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                              {p.name}
                            </dt>
                            <dd className="col-xl-8 col-lg-8 col-md-6 col-sm-12">
                              {formatDate(document.properties.lastModified)}
                            </dd>
                          </Fragment>
                        )
                    )}
                  </dl>
                  <a
                    href={folderUrl + document.name}
                    target="_blank"
                    rel="noreferrer"
                    className="btn btn-primary mt-auto"
                    aria-label={
                      document.name.toLowerCase().includes(".pdf")
                        ? "Download link opens in new tab"
                        : "Download link"
                    }
                  >
                    Download{" "}
                    <i
                      className={
                        document.name.toLowerCase().includes(".pdf")
                          ? "fa fa-external-link-alt"
                          : "fa fa-download"
                      }
                      aria-hidden="true"
                    ></i>
                  </a>
                </div>
              </div>
            </div>
          );
        });
    } else if (allDocuments.length > 0 && documents.length === 0) {
      return "";
    } else if (getBlobsFailed) {
      return (
        <div className="col-12 text-danger">
          <hr />
          <p>
            Unable to retrieve records. Contact recordshelp@bellevuewa.gov for
            inquiries.
          </p>
        </div>
      );
    } else {
      return (
        <Fragment>
          <i
            id="document-spinner"
            className="fa fas fa-spinner fa-spin fa-3x"
            aria-hidden="true"
          ></i>
          <span id="document-spinner-text" className="mt-2" aria-hidden="true">
            Loading results...
          </span>
        </Fragment>
      );
    }
  }

  function renderRecordsCountText() {
    if (documents.length > 0) {
      return (
        <span className="total-records">
          {" "}
          Displaying {pagesVisited + 1} -{" "}
          {pagesVisited + documentsPerPage > documents.length
            ? documents.length
            : pagesVisited + documentsPerPage}{" "}
          of {documents.length} records
        </span>
      );
    }
  }

  function renderPagination() {
    if (documents.length > 0) {
      return (
        <ReactPaginate
          pageLinkClassName={"page-link"}
          pageClassName={"page-item"}
          previousLabel={"Previous"}
          nextLabel={"Next"}
          pageCount={pageCount}
          onPageChange={changePage}
          forcePage={pageNumber - 1}
          containerClassName={"pagination justify-content-center"}
          previousLinkClassName={"page-link"}
          previousClassName={"page-item"}
          nextClassName={"page-item"}
          nextLinkClassName={"page-link"}
          disabledClassName={"disabled"}
          activeClassName={"active"}
          breakClassName={"page-item"}
          breakLinkClassName={"page-link"}
          pageRangeDisplayed={Config.storage.pageRangeDisplayed - 1}
          marginPagesDisplayed={1}
        />
      );
    }
  }

  function handleCollapsibleFiltersBtnOnClick() {
    setShowFilters(!showFilters);
  }

  function renderFiltersToggleBtn() {
    return (
      <button
        id="filtersToggleBtn"
        data-toggle="collapse"
        data-target="#filters"
        onClick={handleCollapsibleFiltersBtnOnClick}
        className="btn btn-outline-primary mb-3 float-right"
        aria-label="Collapsible filter toggle button"
      >
        <i className="fa fas fa-filter"></i>Filters
      </button>
    );
  }

  return (
    <Fragment>
      {config.hidden === true && (
        <Helmet htmlAttributes>
          <meta name="robots" content="noindex" />
        </Helmet>
      )}
      <main className="col-sm-12 col-md-12 col-lg-9 col-xl-10">
        <h1>{config.title}</h1>
        <div className="row">
          <div className="col-12">
            <p>{ReactHtmlParser(config.description)}</p>
          </div>
        </div>
        {!getBlobsFailed && (
          <Fragment>
            <div className="row">
              <div className="col-12">
                {renderSortByDropDown()}
                {filters && renderFiltersToggleBtn()}
              </div>
            </div>
            {filters && renderFilterChips()}
          </Fragment>
        )}
        <div id="documents" className="row">
          {renderDocuments()}
        </div>
        <div className="row">
          <div className="col align-self-end">{renderRecordsCountText()}</div>
        </div>
        <div className="row">
          {" "}
          <div className="col align-self-center">{renderPagination()}</div>
        </div>
      </main>
    </Fragment>
  );
}

function getFolderConfig(array, title) {
  return array.find((element) => {
    return element.name === title;
  });
}

function getSortedData(data, prop, isAsc, datatype) {
  if (prop === "name") {
    if (isAsc) {
      return data.sort((a, b) => a.name.localeCompare(b.name));
    } else {
      return data.sort((a, b) => b.name.localeCompare(a.name));
    }
  } else if (datatype === "string") {
    return data.sort((a, b) => {
      let aStr = a.metadata[prop];
      let bStr = b.metadata[prop];

      if (aStr === bStr) {
        return 0;
      } else if (aStr) {
        return 1;
      } else if (bStr) {
        return -1;
      } else if (isAsc) {
        aStr.localeCompare(bStr);
      } else {
        //descending
        bStr.localeCompare(aStr);
      }
    });
  } else if (datatype === "datetime") {
    if (isAsc) {
      const future = new Date(10000000000000);
      return data.sort((a, b) => {
        let dateA = a.metadata[prop] ? new Date(a.metadata[prop]) : future;
        let dateB = b.metadata[prop] ? new Date(b.metadata[prop]) : future;
        return dateA - dateB;
      });
    } else {
      const past = new Date(-10000000000000);
      return data.sort((a, b) => {
        let dateA = a.metadata[prop] ? new Date(a.metadata[prop]) : past;
        let dateB = b.metadata[prop] ? new Date(b.metadata[prop]) : past;
        return dateB - dateA;
      });
    }
  }
}

function updateSideBarMenu(id) {
  let list = document.getElementById("sidebar-menu").getElementsByTagName("a");
  for (let i = 0; i < list.length; i++) {
    let elem = list[i];
    elem.classList.remove("active");
    elem
      .getElementsByTagName("i")[0]
      .classList.remove("fa", "fa-chevron-right");
  }

  let elem = document.getElementById(id);
  if (elem) {
    elem.classList.add("active");
    elem.getElementsByTagName("i")[0].classList.add("fa", "fa-chevron-right");
  }
}

function formatDate(date) {
  var month = date.getMonth() + 1;
  var day = date.getDate();
  var year = date.getFullYear();
  var hours = date.getHours();
  var minutes = date.getMinutes();
  var ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;

  var strDate = month + "/" + day + "/" + year;
  var strTime = hours + ":" + minutes + " " + ampm;

  return strDate + " " + strTime;
}

export default withRouter(Folder);
