import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";

const UseGaTracker = () => {
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GA_ID);
    ReactGA.set({ groups: "default" });
    ReactGA.set({ anonymizeIp: true });
    ReactGA.set({ allow_ad_personalization_signals: false });
    window.addEventListener("click", function (event) {
      var el = event.target || event.srcElement,
        track = true,
        href =
          typeof el.getAttribute("href") != "undefined"
            ? el.getAttribute("href")
            : "",
        fileTypes =
          /\.(avi|csv|dat|dmg|doc.*|exe|flv|gif|jpg|mov|mp3|mp4|msi|pdf|png|ppt.*|rar|swf|txt|wav|wma|wmv|xls.*|zip|dwg|dotx|pub|)$/i;

      if (href != null && !href.match(/^javascript:/i)) {
        var isThisDomain = href.match(
            document.domain.split(".").reverse()[1] +
              "." +
              document.domain.split(".").reverse()[0]
          ),
          elEv = [],
          extension = "";

        elEv.value = 0;
        elEv.non_i = false;
        if (href.match(/^mailto:/i)) {
          elEv.category = "email";
          elEv.action = "click";
          elEv.label = href.replace(/^mailto:/i, "");
          elEv.loc = href;
        } else if (href.match(fileTypes && !isThisDomain)) {
          extension = /[.]/.exec(href) ? /[^.]+$/.exec(href) : undefined;
          elEv.category = "external-download";
          elEv.action = "click-" + extension[0];
          elEv.label = href.replace(/ /g, "-");
          elEv.loc = href;
        } else if (href.match(fileTypes)) {
          extension = /[.]/.exec(href) ? /[^.]+$/.exec(href) : undefined;
          elEv.category = "download";
          elEv.action = "click-" + extension[0];
          elEv.label = href.replace(/ /g, "-");
          elEv.loc = href;
        } else if (href.match(/^https?:/i) && !isThisDomain) {
          elEv.category = "external";
          elEv.action = "click";
          elEv.label = href.replace(/^https?:\/\//i, "");
          elEv.non_i = true;
          elEv.loc = href;
        } else if (href.match(/^tel:/i)) {
          elEv.category = "telephone";
          elEv.action = "click";
          elEv.label = href.replace(/^tel:/i, "");
          elEv.loc = href;
        } else {
          track = false;
        }

        if (track) {
          ReactGA.event({
            category: elEv.category.toLowerCase(),
            action: elEv.action.toLowerCase(),
            label: elEv.label.toLowerCase(),
            value: elEv.value,
          });
          if (
            el.getAttribute("target") === undefined ||
            el.getAttribute("target").toLowerCase() !== "_blank"
          ) {
            setTimeout(function () {
              location.href = elEv.loc;
            }, 400);
            return false;
          }
        }
      }
    });
    setInitialized(true);
  }, []);

  useEffect(() => {
    if (initialized) {
      ReactGA.pageview(location.pathname + location.search);
    }
  }, [initialized, location]);
};

export default UseGaTracker;
