import React from "react";
import { Link } from "react-router-dom";
import Config from "../config.json";

function SideNav() {
  const folders = Config.storage.folders
    .filter((f) => f.hidden !== true)
    .sort((a, b) => a.name.localeCompare(b.name));

  return (
    <aside className="col-sm-12 col-md-12 col-lg-3  col-xl-2 region-sidebar-left mb-4">
      <h2 className="sidebar-menu-header">
        <Link to="/" className="h3">
          <span>Document Center</span>
        </Link>
      </h2>
      <nav id="sidebar-menu" aria-label="Side navigation listing folder links">
        {folders.map((folder, i) => (
          <Link key={folder.name} id={folder.name} to={folder.routePath}>
            <i></i>
            {folder.title}
          </Link>
        ))}
      </nav>
    </aside>
  );
}

export default SideNav;
