import React from "react";
import Config from "../config.json";

function SiteMap() {
  function renderList() {
    return (
      <ul className="list-unstyled" aria-labelledby="sitemap">
        <li>
          <a href={process.env.PUBLIC_URL}>Home</a>
        </li>
        {Config.storage.folders
          .filter((f) => f.hidden !== true)
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((folder, i) => (
            <li>
              <a href={process.env.PUBLIC_URL + "/#" + folder.routePath}>
                {folder.name}
              </a>
            </li>
          ))}
      </ul>
    );
  }

  return (
    <main className="col-sm-12 col-md-12 col-lg-9 col-xl-10">
      <h1 id="sitemap">Site Map</h1>
      {renderList()}
    </main>
  );
}

export default SiteMap;
