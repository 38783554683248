import React from "react";
import { Route, Switch } from "react-router-dom";
import {
  Header,
  Footer,
  Home,
  Search,
  Folder,
  SideNav,
  NotFound,
  UseGaTracker,
  SiteMap,
} from "./components";
import Config from "./config.json";

function App() {
  if (process.env.NODE_ENV === "production") {
    UseGaTracker();
  }

  return (
    <div className="App">
      <Header />
      <div id="main-content" className="main">
        <div className="row">
          <SideNav />
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route exact path="/Search">
              <Search />
            </Route>
            {Config.storage.folders.map((folder, i) => (
              <Route key={folder.routePath} exact path={folder.routePath}>
                <Folder folder={folder.name} />
              </Route>
            ))}
            <Route exact path="/Sitemap">
              <SiteMap />
            </Route>
            <Route>
              <NotFound />
            </Route>
          </Switch>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default App;
