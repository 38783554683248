import axios from "axios";
import React, { useState, useEffect, Fragment } from "react";
import queryString from "query-string";
import { useLocation, withRouter } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import Config from "../config.json";
import ReactPaginate from "react-paginate";
import { decode } from "html-entities";
import { Chip } from "@react-md/chip";
import "../react-md-styles.css";

function Search(props) {
  const header = {
    "api-key": process.env.REACT_APP_SEARCH_SERVICE_API_KEY,
    "content-type": "application/json",
  };
  const FILTER_ALL = "ALL";
  const { search } = useLocation();
  const queryParams = queryString.parse(search);
  const [selectedFilter, setSelectedFilter] = useState(
    queryParams.filter ? queryParams.filter.replaceAll(" ", "") : FILTER_ALL
  );
  const [searchTerm, setSearchTerm] = useState();
  const [currentSearchRequest, setCurrentSearchRequest] = useState();
  const [searchResults, setSearchResults] = useState([]);
  const [totalCountOfMatches, setTotalCountOfMatches] = useState(0);
  const [countOfFilteredResults, setCountOfFilteredResults] = useState(0);
  const [searchCompleted, setSearchCompleted] = useState(false);
  const [prefixCountMap, setPrefixCountMap] = useState(new Map());
  const [searchFailed, setSearchFailed] = useState(false);

  const [pageNumber, setPageNumber] = useState(1);
  const resultsPerPage = Config.search.resultsPerPage;
  var pagesVisited = (pageNumber - 1) * resultsPerPage;
  const [pageCount, setPageCount] = useState(0);
  const [pageMarker, setPageMarker] = useState(
    Config.search.pageRangeDisplayed
  );

  if (isNewSearch()) {
    resetState();
  }

  //#region pagination and filtering events
  const changePage = ({ selected }) => {
    window.scrollTo(0, 0);
    let selectedPageNum = selected + 1;
    setPageNumber(selectedPageNum);

    // load additional page ahead of time
    if (selectedPageNum === pageMarker - 1) {
      let newPageMarker = pageMarker + 1;
      setPageMarker(newPageMarker);
      loadAdditionalResults(Config.search.resultsPerPage);
    } else if (selectedPageNum === pageMarker) {
      // load additional 2 pages ahead of time
      let newPageMarker = pageMarker + 2;
      setPageMarker(newPageMarker);
      loadAdditionalResults(Config.search.resultsPerPage * 2);
    } else if (selectedPageNum > pageMarker) {
      // backfill results
      let numOfPagesToBackfill = selectedPageNum - pageMarker;
      let backFillNum = numOfPagesToBackfill * resultsPerPage;

      // load additional 2 pages ahead of time
      let newPageMarker = selectedPageNum + 2;
      setPageMarker(newPageMarker);
      let additionalNum = Config.search.resultsPerPage * 2;

      loadAdditionalResults(backFillNum + additionalNum);
    }
  };

  function handleFilterClick(prefix) {
    setSearchCompleted(false);
    setPageNumber(1);

    if (selectedFilter === prefix) {
      props.history.push("/Search?text=" + queryParams.text);
      setSelectedFilter(FILTER_ALL);
    } else {
      props.history.push(
        "/Search?text=" + queryParams.text + "&filter=" + prefix
      );
      setSelectedFilter(prefix);
    }
  }
  //#endregion

  //#region useEffectHooks
  //Executes once during initialization
  useEffect(() => {
    document.title = process.env.REACT_APP_MAIN_TITLE + " - Search Results";
  }, []);

  useEffect(() => {
    if (!isInvalidSearchTerm()) {
      let body = Object.assign({}, Config.search.requestBody);
      body.top =
        Config.search.resultsPerPage * Config.search.pageRangeDisplayed;
      body.search = transformSearchText(queryParams.text);
      setCurrentSearchRequest(body.search);

      //Search for an exact match. If no exact matches are found and fuzzy search is enabled, perform fuzzy search.
      axios
        .post(process.env.REACT_APP_SEARCH_SERVICE_URL, body, {
          headers: header,
        })
        .then(
          (response) => {
            if (response.data["@odata.count"] > 0) {
              setTotalCountOfMatches(response.data["@odata.count"]);
              setSearchResults([...response.data.value]);
              initPrefixCountMap(body.search);
              setPageCount(
                Math.ceil(response.data["@odata.count"] / resultsPerPage)
              );

              if (selectedFilter !== FILTER_ALL) {
                updateSearchResults(selectedFilter, body.search);
              } else {
                setSearchCompleted(true);
              }
            } else {
              if (Config.search.fuzzySearch) {
                // perform fuzzy search, if exact matches were not found
                body.queryType = "full";
                body.search = getFuzzySearchText(queryParams.text);
                setCurrentSearchRequest(body.search);

                axios
                  .post(process.env.REACT_APP_SEARCH_SERVICE_URL, body, {
                    headers: header,
                  })
                  .then(
                    (fuzzySearchResponse) => {
                      setTotalCountOfMatches(
                        fuzzySearchResponse.data["@odata.count"]
                      );
                      setSearchResults([...fuzzySearchResponse.data.value]);
                      initPrefixCountMap(body.search, true);
                      setPageCount(
                        Math.ceil(
                          fuzzySearchResponse.data["@odata.count"] /
                            resultsPerPage
                        )
                      );

                      if (selectedFilter !== FILTER_ALL) {
                        updateSearchResults(selectedFilter, body.search);
                      } else {
                        setSearchCompleted(true);
                      }
                    },
                    (error) => {
                      console.log(
                        "Fuzzy search lookup encountered error: " + error
                      );
                      setSearchFailed(true);
                    }
                  );
              } else {
                setSearchCompleted(true);
              }
            }
          },
          (error) => {
            console.log("Exact search lookup encountered error: " + error);
            setSearchFailed(true);
          }
        );
    }

    deactivateSideBarMenu();
  }, [searchTerm]);

  useEffect(() => {
    if (totalCountOfMatches === 0) {
      return;
    }
    setSearchCompleted(false);
    updateSearchResults(selectedFilter);
  }, [selectedFilter]);
  //#endregion

  return (
    <main className="col-sm-12 col-md-12 col-lg-9 col-xl-10">
      <h1>Search Results</h1>
      {renderResultsSummary()}
      <div id="searchFilters" className="row">
        <div className="col-12 mb-2">{renderFilterChips()}</div>
      </div>
      <div id="searchResults" className="row">
        <div className="col-12">{renderResults()}</div>
      </div>
      <div className="row">
        <div className="col align-self-end">{renderRecordsCountText()}</div>
      </div>
      <div className="row">
        <div className="col align-self-center">{renderPagination()}</div>
      </div>
    </main>
  );

  //#region rendering helper function
  function renderRecordsCountText() {
    if (
      searchCompleted &&
      searchResults.slice(pagesVisited, pagesVisited + resultsPerPage).length >
        0
    ) {
      let count =
        selectedFilter === FILTER_ALL
          ? totalCountOfMatches
          : countOfFilteredResults;

      return (
        <span className="total-records">
          {" "}
          Displaying {pagesVisited + 1} -{" "}
          {pagesVisited + resultsPerPage > count
            ? count
            : pagesVisited + resultsPerPage}{" "}
          of {count} records
        </span>
      );
    }
  }

  function renderMetadata(record) {
    return (
      <Fragment>
        <dl className="card-text row mb-2">
          <Fragment>
            <dt className="col-xl-2 col-lg-3 col-md-4 col-sm-12 ">Category:</dt>
            <dd className="col-xl-10 col-lg-9 col-md-8 col-sm-12">
              {record.prefix.replace(/([A-Z])/g, " $1").trim()}
            </dd>
          </Fragment>
          {Config.search.metadata?.map(
            (m, i) =>
              record[m.id] !== undefined &&
              record[m.id] !== null && (
                <Fragment>
                  <dt className="col-xl-2 col-lg-3 col-md-4 col-sm-12 ">
                    {m.name}:
                  </dt>
                  <dd className="col-xl-10 col-lg-9 col-md-8 col-sm-12">
                    {Config.storage.metadataDateTimeFields.includes(m.id)
                      ? record[m.id].split(" ")[0]
                      : decode(record[m.id])}
                  </dd>
                </Fragment>
              )
          )}
        </dl>
      </Fragment>
    );
  }

  function renderResults() {
    if (searchFailed) {
      return (
        <div className="col-12 text-danger">
          <hr />
          <p>
            Unable to retrieve records. Contact recordshelp@bellevuewa.gov for
            inquiries.
          </p>
        </div>
      );
    }

    if (
      !searchCompleted &&
      !isInvalidSearchTerm() &&
      selectedFilter === FILTER_ALL
    ) {
      return (
        <Fragment>
          <i
            id="document-spinner"
            className="fa fas fa-spinner fa-spin fa-3x"
            aria-hidden="true"
          ></i>
          <span id="document-spinner-text" className="mt-2" aria-hidden="true">
            Loading results...
          </span>
        </Fragment>
      );
    }

    if (
      isInvalidSearchTerm() ||
      (searchCompleted && totalCountOfMatches === 0) ||
      (searchCompleted &&
        selectedFilter !== FILTER_ALL &&
        countOfFilteredResults === 0)
    ) {
      return;
    }

    return searchResults
      .slice(pagesVisited, pagesVisited + resultsPerPage)
      .map((r, i) => (
        <div className="card mb-4">
          <div className="card-header">
            <h2 className="card-title h4">
              <a
                target="_blank"
                rel="noreferrer"
                href={URLTokenDecode(r.metadata_storage_path)}
                aria-label={
                  r.metadata_storage_path.toLowerCase().includes(".pdf")
                    ? "Link to open .pdf file in new tab"
                    : "Link to download file"
                }
              >
                {r.metadata_storage_name}{" "}
                <i
                  className={
                    r.metadata_storage_name.toLowerCase().includes(".pdf")
                      ? "fa fa-external-link-alt"
                      : "fa fa-download"
                  }
                  aria-hidden="true"
                ></i>
              </a>
            </h2>
            {renderMetadata(r)}
          </div>
          <div className="card-body">
            {r["@search.highlights"]?.content?.map((c, i) => (
              <p className="card-text">{ReactHtmlParser(c)} </p>
            ))}
            <span className="d-block mt-2">
              <a
                href={URLTokenDecode(r.metadata_storage_path)}
                target="_blank"
                rel="noreferrer"
                aria-label={
                  r.metadata_storage_path.toLowerCase().includes(".pdf")
                    ? "Link to open .pdf file in new tab"
                    : "Link to download file"
                }
              >
                {URLTokenDecode(r.metadata_storage_path)}{" "}
                <i
                  className={
                    r.metadata_storage_name.toLowerCase().includes(".pdf")
                      ? "fa fa-external-link-alt"
                      : "fa fa-download"
                  }
                  aria-hidden="true"
                ></i>
              </a>
            </span>
          </div>
        </div>
      ));
  }

  function renderResultsSummary() {
    if (isInvalidSearchTerm()) {
      return (
        <p>
          Your search for <b>'{searchTerm}'</b> is invalid
        </p>
      );
    }

    let categoryText = "";
    let count = totalCountOfMatches;
    if (
      searchCompleted &&
      selectedFilter !== FILTER_ALL &&
      countOfFilteredResults === 0
    ) {
      categoryText = " in the category <b>" + queryParams.filter + "</b> ";
      count = 0;
    }

    if (count > 0) {
      return (
        <Fragment>
          <p>
            Your search for <b>'{searchTerm}'</b> matched <b>{count}</b> records{" "}
          </p>
        </Fragment>
      );
    }
    if (searchCompleted && count === 0) {
      return (
        <Fragment>
          <p>
            Your search for <b>'{searchTerm}'</b> matched <b>0</b> records{" "}
            {ReactHtmlParser(categoryText)}
          </p>
        </Fragment>
      );
    }
  }

  function renderPagination() {
    if (searchResults.length > 0) {
      return (
        <ReactPaginate
          pageLinkClassName={"page-link"}
          pageClassName={"page-item"}
          previousLabel={"Previous"}
          nextLabel={"Next"}
          pageCount={pageCount}
          onPageChange={changePage}
          forcePage={pageNumber - 1}
          containerClassName={"pagination justify-content-center"}
          previousLinkClassName={"page-link"}
          previousClassName={"page-item"}
          nextClassName={"page-item"}
          nextLinkClassName={"page-link"}
          disabledClassName={"disabled"}
          activeClassName={"active"}
          breakClassName={"page-item"}
          breakLinkClassName={"page-link"}
          pageRangeDisplayed={Config.search.pageRangeDisplayed - 1}
          marginPagesDisplayed={0}
        />
      );
    }
  }

  function renderFilterChips() {
    if (isInvalidFilter()) {
      return;
    }

    return (
      <Fragment>
        <label for="filter-buttons-group" class="sr-only">
          Filter by folder
        </label>
        <div
          name="filter-buttons-group"
          class="btn-group"
          role="group"
          aria-label="Filter buttons by folder name"
        >
          <ul className="pl-0">
            {Array.from(prefixCountMap.keys())
              .sort()
              .map((prefix) => {
                if (prefixCountMap.get(prefix) > 0) {
                  return (
                    <li className="d-inline">
                      <Chip
                        theme="solid"
                        disableIconTransition
                        role="button"
                        key={prefix}
                        selected={selectedFilter === prefix}
                        onClick={(e) => {
                          e.currentTarget.blur();
                          handleFilterClick(prefix);
                        }}
                      >
                        {prefix.replace(/([A-Z])/g, " $1").trim() +
                          " (" +
                          prefixCountMap.get(prefix) +
                          ")"}
                      </Chip>
                    </li>
                  );
                }
              })}
          </ul>
        </div>{" "}
      </Fragment>
    );
  }
  //#endregion

  function initPrefixCountMap(searchText, isFuzzySearch = false) {
    const prefixes = Config.storage.folders.map((f) => f.name);

    prefixes.forEach((prefix) => {
      let body = Object.assign({}, Config.search.requestBody);
      body.search = searchText;
      body.filter = "prefix eq '" + prefix + "'";
      body.top = 1;
      body.highlightPreTag = "content-1";
      if (isFuzzySearch) {
        body.queryType = "full";
      }

      axios
        .post(process.env.REACT_APP_SEARCH_SERVICE_URL, body, {
          headers: header,
        })
        .then(
          (response) => {
            setPrefixCountMap(
              (prev) =>
                new Map([...prev, [prefix, response.data["@odata.count"]]])
            );
          },
          (error) => {
            console.log(
              "Prefix search filter lookup encountered error: " + error
            );
            setSearchFailed(true);
          }
        );
    });
  }

  function loadAdditionalResults(top) {
    let body = Object.assign({}, Config.search.requestBody);
    body.search = currentSearchRequest;
    body.top = top;
    body.skip = searchResults.length;
    if (selectedFilter !== FILTER_ALL) {
      body.filter = "prefix eq '" + selectedFilter + "'";
    }

    axios
      .post(process.env.REACT_APP_SEARCH_SERVICE_URL, body, {
        headers: header,
      })
      .then(
        (response) => {
          setSearchResults(searchResults.concat(response.data.value));
        },
        (error) => {
          console.log(
            "Error loading additional results. Error message: " + error
          );
          setSearchFailed(true);
        }
      );
  }

  function transformSearchText(searchText) {
    var searchWords = searchText.replaceAll('"', "").toLowerCase().split(" ");

    if (searchWords.includes("or") || searchWords.includes("and")) {
      return searchWords.join(" ").replaceAll("and", "+").replaceAll("or", "|");
    } else if (searchWords.length > 1) {
      return '"' + searchWords.join(" ") + '"';
    } else {
      return searchText;
    }
  }

  function getFuzzySearchText(searchText) {
    var stopWords = Config.search.stopWords;
    var searchWords = searchText.split(" ");

    if (searchWords.length > 1) {
      let result = "";

      for (var i = 0; i < searchWords.length; i++) {
        // word is a stop-word or a number
        if (
          stopWords.includes(searchWords[i].toLowerCase()) ||
          !isNaN(searchWords[i])
        ) {
          result += searchWords[i];
          result += " ";
        } else {
          result += searchWords[i];
          result += "~ ";
        }
      }

      return result;
    } else {
      if (stopWords.includes(searchText.toLowerCase()) || !isNaN(searchText)) {
        return searchText;
      } else {
        return searchText + "~";
      }
    }
  }

  function updateSearchResults(prefix, searchText = "") {
    let body = Object.assign({}, Config.search.requestBody);
    body.top = Config.search.resultsPerPage * Config.search.pageRangeDisplayed;
    body.search = searchText !== "" ? searchText : currentSearchRequest;
    if (prefix !== FILTER_ALL) {
      body.filter = "prefix eq '" + prefix + "'";
    }
    if (body.search.includes("~")) {
      body.queryType = "full";
    }

    axios
      .post(process.env.REACT_APP_SEARCH_SERVICE_URL, body, {
        headers: header,
      })
      .then((res) => res.data)
      .then(
        (result) => {
          if (selectedFilter === FILTER_ALL) {
            setPageCount(Math.ceil(totalCountOfMatches / resultsPerPage));
            setCountOfFilteredResults(0);
          } else {
            setPageCount(Math.ceil(result["@odata.count"] / resultsPerPage));
            setCountOfFilteredResults(result["@odata.count"]);
          }

          setSearchResults([...result.value]);
          setSearchCompleted(true);
          setPageMarker(Config.search.pageRangeDisplayed);
        },
        (error) => {
          console.log("Updating search results encountered error: " + error);
          setSearchFailed(true);
        }
      );
  }

  function isNewSearch() {
    return queryParams.text !== searchTerm;
  }

  function isInvalidFilter() {
    return (
      searchCompleted &&
      selectedFilter !== FILTER_ALL &&
      countOfFilteredResults === 0
    );
  }

  function isInvalidSearchTerm() {
    return queryParams.text === "";
  }

  function resetState() {
    setPageNumber(1);
    pagesVisited = 0;
    setPageCount(0);
    setPageMarker(Config.search.pageRangeDisplayed);
    setSearchCompleted(false);
    setSearchTerm(queryParams.text);
    setSearchResults([]);
    setTotalCountOfMatches(0);
    setPrefixCountMap(new Map());
    setSearchFailed(false);
    setSelectedFilter(
      queryParams.filter ? queryParams.filter.replaceAll(" ", "") : FILTER_ALL
    );
    setCurrentSearchRequest("");
    setCountOfFilteredResults(0);
  }
}

function deactivateSideBarMenu() {
  let list = document.getElementById("sidebar-menu").getElementsByTagName("a");
  for (let i = 0; i < list.length; i++) {
    let elem = list[i];
    elem.classList.remove("active");
    elem
      .getElementsByTagName("i")[0]
      .classList.remove("fa", "fa-chevron-right");
  }
}

// Javascript equivalent to HttpServerUtility.UrlTokenDecode in .NET
// The metadata_storage_path field is encoded with padding, so a simple base64 decoding
// will not work. Refer to official documentation for more details: https://docs.microsoft.com/en-us/azure/search/search-indexer-field-mappings
function URLTokenDecode(token) {
  if (token.length == 0) return "";

  // The last character in the token is the number of padding characters.
  var numberOfPaddingCharacters = token.slice(-1);

  // The Base64 string is the token without the last character.
  token = token.slice(0, -1);

  // '-'s are '+'s and '_'s are '/'s.
  token = token.replace(/-/g, "+");
  token = token.replace(/_/g, "/");

  // Pad the Base64 string out with '='s
  for (var i = 0; i < numberOfPaddingCharacters; i++) token += "=";

  return Buffer.from(token, "base64").toString();
}

export default withRouter(Search);
