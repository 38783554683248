import React, { useEffect } from "react";

function NotFound() {
  //Executes once during initialization
  useEffect(() => {
    document.title = process.env.REACT_APP_MAIN_TITLE + " - Page Not Found";
  }, []);

  return (
    <main className="col-sm-12 col-md-12 col-lg-9 col-xl-10">
      <h1>Page not found</h1>
      <p>The requested page could not be found.</p>
    </main>
  );
}

export default NotFound;
